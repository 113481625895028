import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const contentfulHtml = (richtext: any): string => {
	if (!richtext) {
		return '';
    }
    let raw = documentToHtmlString(richtext);
    const re = /<a href="custom:\/\/(.+?)">(.+?)<\/a>/g;
    const arr = re.exec(raw) || [];
    for (let i = 0; i < arr.length; i += 3) {
      raw = raw.replace(
        arr[i],
        `<${arr[i + 1]}>${arr[i + 2]}</${arr[i + 1]}>`
      );
    }
    return raw;
}
