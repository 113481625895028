
import { slug } from "@/utils";
export default {
  props: {
    data: Object,
  },
  methods: {
    slug: slug,
  },
};
