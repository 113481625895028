
import { contentfulHtml, slug } from "@/utils";
declare const window: any;
export default {
  props: {
    data: Object,
  },
  methods: {
    contentfulHtml: contentfulHtml,
    slug: slug,
  },
  created: function () {
    setTimeout(function(){
        window.$('.portfolio-content .item').magnificPopup({
            delegate: '.icon-img',
            type: 'image',
            gallery: {
                enabled: true
            }
        });
    }, 1000);
  },
};
