import Vue from "vue";
import Vuex from "vuex";
 
Vue.use(Vuex);
 
export default new Vuex.Store({
    state: {
        contentSource: '',
        contentful: {}
    },
    getters: {},
    mutations: {
        setContentful(state, content) {
            state.contentful = content;
        },
        setContentSourceOnline(state) {
            state.contentSource = 'online';
        },
        setContentSourceOffline(state) {
            state.contentSource = 'offline';
        }
    },
    actions: {}
});
