
import { contentfulHtml, slug } from "@/utils";

export default {
  props: {
    data: Object,
  },
  data() {
    return {
      bgHome: (url: string): string => {
        return `background: url("${url}") no-repeat center center fixed;
            background-size: cover;
            -webkit-background-size: cover;
            -moz-background-size: cover; 
            -o-background-size: cover;`;
      },
    };
  },
  methods: {
    contentfulHtml: contentfulHtml,
    slug: slug,
  },
};
