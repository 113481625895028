
import { Component, Vue } from "vue-property-decorator";
import ControlBar from "./components/ControlBar.vue";
import RawRayhan from "./components/RawRayhan.vue";
import SlideMenu from "./components/SlideMenu.vue";

@Component({
  components: {
    ControlBar,
    SlideMenu,
    RawRayhan,
  },
})
export default class App extends Vue {}
