
import Home from "./Home.vue";
import About from "./About.vue";
import Services from "./Services.vue";
import Resume from "./Resume.vue";
import Skills from "./Skills.vue";
import Portfolio from "./Portfolio.vue";
import Facts from "./Facts.vue";
import Testimonials from "./Testimonials.vue";
import Blog from "./Blog.vue";
import Contact from "./Contact.vue";
import Footer1 from "./Footer1.vue";
import ScrollUp from "./ScrollUp.vue";
import store from '@/store';

export default {
  computed: {
    contentSource() {
      return store.state.contentSource;
    },
    components() {
      return store.state.contentful;
    },
    tempComponents() {
      return [
        //{"type": "resume"},
        //{"type": "skills"},
        //{"type": "portfolio"},
        //{"type": "facts"},
        //{"type": "testimonials"},
        //{"type": "blog"},
        {"type": "contact"},
        {"type": "footer-1"},
        {"type": "scroll-up"},
      ]
    },
  },
  components: {
    Home,
    About,
    Services,
    Skills,
    Portfolio,
    Blog,
    Contact,
    Footer1,
    Facts,
    Testimonials,
    Resume,
    ScrollUp,
  },
};
