import Vue from 'vue'
import App from './App.vue'
import { createClient } from 'contentful';
import store from "./store";

Vue.config.productionTip = false

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

new Vue({
  store,
  mounted: async () => {
    try {
      const client = createClient({
        space: '5he42ih2gv5p',
        accessToken: 'EctVW8ncTrLIw4Z7Ks9SAJ_59GTsfGCWJMDB6-tzZYU'
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const entry = await client.getEntry<any>('RSzSbnw6gGQW5AHBHqcPJ');
      if (entry.sys.type === 'Error') {
        return;
      }
      
      store.commit('setContentful', entry.fields.body);
      store.commit('setContentSourceOnline');
    } catch {
      store.commit('setContentful', window.content.body);
      store.commit('setContentSourceOffline');
    }
  },
  render: h => h(App),
}).$mount('#app')
