
import { contentfulHtml, slug } from "@/utils";
export default {
  props: {
    data: Object,
  },
  data() {
    return {
      bgHome: (url: string) => {
        return `background: url("${url}") center center no-repeat fixed;`;
      },
    };
  },
  methods: {
    contentfulHtml: contentfulHtml,
    slug: slug,
  },
};
